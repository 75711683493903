import React, { useState } from 'react';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const Navbar = () => {
  const {
    i18nState: { lang },
    t,
    changeLang,
  } = useI18nContext();

  return (
    <header className="header current-career-vacancies " id="header">
      <nav className="_nav_wrapper">
        <a
          href="https://www.nptcgroup.ac.uk/"
          id="_nav_main_logo"
          className="_nav_wrapper_left _nav_wrapper_logo"
        ></a>

        <div className="_nav_wrapper_right _nav_wrapper_menu">
          <div className="_nav_wrapper_menu_items">
            <div className="_nav_wrapper_menu_item_wrap">
              <a
                href="/"
                className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_desktop"
              >
                {t('navbar.careers-page')}
              </a>
              <a
                href="/"
                className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_mobile"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M503.401 228.884l-43.253-39.411V58.79c0-8.315-6.741-15.057-15.057-15.057H340.976c-8.315 0-15.057 6.741-15.057 15.057v8.374l-52.236-47.597c-10.083-9.189-25.288-9.188-35.367-.001L8.598 228.885c-8.076 7.36-10.745 18.7-6.799 28.889 3.947 10.189 13.557 16.772 24.484 16.772h36.689v209.721c0 8.315 6.741 15.057 15.057 15.057h125.913c8.315 0 15.057-6.741 15.057-15.057V356.931H293v127.337c0 8.315 6.741 15.057 15.057 15.057h125.908c8.315 0 15.057-6.741 15.056-15.057V274.547h36.697c10.926 0 20.537-6.584 24.484-16.772 3.945-10.19 1.277-21.53-6.801-28.891zm-69.436 15.549c-8.315 0-15.057 6.741-15.057 15.057v209.721h-95.793V341.874c0-8.315-6.742-15.057-15.057-15.057H203.942c-8.315 0-15.057 6.741-15.057 15.057v127.337h-95.8V259.49c0-8.315-6.741-15.057-15.057-15.057H36.245l219.756-200.24 74.836 68.191a15.061 15.061 0 0016.224 2.644 15.06 15.06 0 008.973-13.774V73.847h74.002v122.276c0 4.237 1.784 8.276 4.916 11.13l40.803 37.18h-41.79z" />
                </svg>
              </a>
            </div>
            <div className="_nav_wrapper_menu_item_wrap">
              <a
                className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_desktop"
                onClick={(e) =>
                  changeLang(lang === 'en-GB' ? 'cy-GB' : 'en-GB')
                }
                style={{ cursor: 'pointer' }}
              >
                {lang === 'en-GB' ? 'Cymraeg' : 'English'}
              </a>
              <a
                onClick={(e) =>
                  changeLang(lang === 'en-GB' ? 'cy-GB' : 'en-GB')
                }
                style={{ cursor: 'pointer' }}
                className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_mobile"
              >
                {lang === 'en-GB' ? 'Cym' : 'En'}
              </a>
              <a
                href="/"
                className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_mobile"
              >
                {t('navbar.careers-page')}
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
