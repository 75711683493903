import PropTypes from 'prop-types';
import React from 'react';

import { useI18nContext } from '../i18n/i18n';

const Footer = ({ siteTitle }) => {
  const { t } = useI18nContext();

  return (
    <footer>
      <div className="container">
        <div className="_customer-services_1kqab4">
          {t('footer.any-questions')}{' '}
          <a href="mailto:jobs@nptcgroup.ac.uk">jobs@nptcgroup.ac.uk</a>
        </div>
        <div className="_copyright_1kqab4">
          © 2022 {t('footer.company-name')}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
